//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import menu_inserimento_mixin from "../mixins/menu_inserimento_specie_mixin";
export default {
  mixins: [menu_inserimento_mixin],
  data() {
    return {
      x: 0,
      y: 0,
    };
  },
  methods: {
    show: function (e) {
      e.preventDeafault();
      this.men = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.men = true;
      });
    },
  },
};
