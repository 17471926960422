import axios from "axios";

export default {
  state: {
    carrello: [],
  },
  getters: {
    get_lista_carrello: (state) => {
      return state.carrello;
    },
    get_qta_carrello: (state) => {
      return state.carrello.length;
    },
    get_qta_colore: (state) => (payload) => {
      let prodotto = state.carrello.find(
        (art) => art.art == payload.id_prodotto
      );
      if (!prodotto) {
        return 0;
      }
      let colore = prodotto.colori.find((col) => col.id == payload.id_colore);
      if (!colore) {
        return 0;
      }
      return colore.numero;
    },
    get_prodotto_carrello: (state) => (id_prodotto) => {
      return state.carrello.find((prodotto) => prodotto.art == id_prodotto);
    },
  },
  mutations: {
    set_lista_carrello(state, carrello) {
      state.carrello = carrello;
    },
    svuota_carrello(state) {
      state.carrello = [];
    },
    set_commento(state, payload) {
      state.carrello.find((art) => art.art == payload.id_prodotto).commento =
        payload.commento;
    },
  },
  actions: {
    fetch_carrello(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/carrello";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_lista_carrello", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invio_colore(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/carrello/" +
          payload.id_prodotto +
          "/" +
          payload.id_colore +
          "/" +
          payload.qta;
        let auth = context.getters.get_credential;
        axios
          .post(url, { nome_serie: payload.nome_serie }, { auth: auth })
          .then(() => {
            context.dispatch("fetch_carrello");
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    invio_commento(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/carrello/commento/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;
        axios
          .post(url, { commento: payload.commento }, { auth: auth })
          .then(() => {
            context.commit("set_commento", payload);
            // context.dispatch("fetch_carrello");
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    rimuovi_prodotto_carrello(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/carrello/" + id;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("azzera_prodotto", id);
            context.dispatch("fetch_carrello");
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    concludi_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/finalizza";
        let auth = context.getters.get_credential;
        payload.makeByAdmin = false;
        payload.notifica = true;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.dispatch("fetch_prodotti");
            context.commit("svuota_carrello");
            context.commit("add_ordine", JSON.parse(res.data));
            resolve(JSON.parse(res.data));
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
