//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DialogDettaglio from "./dialog_inserimento/listino_dettaglio_prodotto_desktop";
import FotoGallery from "./listino_fotogallery_desktop";
import prod_element_mixin from "../mixins/listino_prodotto_element_mixin";
import InformationBox from "./information_box";
export default {
  components: {
    DialogDettaglio,
    FotoGallery,
    InformationBox
  },
  mixins: [prod_element_mixin],
  data() {
    return {
      show_dialog: false,
    };
  },
  props: {
    show: Boolean,
  },
};
