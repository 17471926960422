//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import SerieInfo from "./information_box_serie.vue";
export default {
  name: "InformationBox",
  components: {
    SerieInfo,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-prodotto#info-prodotto" && this.show_dialog) {
        this.show_dialog = false;
      }
    },
  },
  data() {
    return {
      show_dialog: false,
      loading_info: true,
      all_info: null,
      specie: null,
      qr_image: null,
    };
  },
  computed: {
    avatar_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/specie/" +
        this.specie.id +
        "/img?md5=" +
        this.specie.avatar_md5
      );
    },
    info_img_src() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/specie/" +
        this.specie.id +
        "/info_img?md5=" +
        this.all_info.info_img_md5
      );
    },
  },
  methods: {
    async open() {
      this.show_dialog = true;
      this.$router.push("#dettaglio-prodotto#info-prodotto");
      this.loading_info = true;
      await this.load_specie();
      await this.load_info();
      this.qr_image =
        process.env.VUE_APP_API_ROOT +
        "/admin/lotti/qrcode?cod=" +
        this.item.cod +
        "&spec=" +
        this.specie.short_id;
      this.loading_info = false;
    },
    close() {
      this.show_dialog = false;
      this.$router.back();
    },
    load_specie() {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/specie/" + this.item.specie;
        axios
          .get(url)
          .then((response) => {
            this.specie = response.data;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    load_info() {
      return new Promise((resolve, reject) => {
        this.loading_info = true;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/specie/" +
          this.item.specie +
          "/info";
        axios
          .get(url)
          .then((response) => {
            this.all_info = response.data;
            this.loading_info = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.loading_info = false;
            reject();
          });
      });
    },

    formatText(input) {
      return input.replace(
        /\*\*(.*?)\*\*/g,
        '<span style="font-weight: bold; font-size: 16px;">$1</span>'
      );
    },
    async downloadQr() {
      try {
        const response = await fetch(this.qr_image);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = this.specie.nome + ".png";
        link.style.display = "none"; // Nascondi il link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading the image:", error);
      }
    },
  },
};
