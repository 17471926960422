import _ from "lodash";

export default {
  props: {
    prodotto: Object,
    colore: Object,
  },
  created() {
    this.debounceqta = _.debounce(this.set_qta, 1000);
  },
  data() {
    return {
      men: false,
      qta: this.$store.getters.get_qta_colore({
        id_prodotto: this.prodotto.id,
        id_colore: this.colore.id,
      }),
      loading: false,
      adding: false,
      removing: false,
      messaggio: "",
    };
  },
  watch: {
    qta: function () {
      if (isNaN(this.qta)) {
        return;
      }
      if (this.qta < 0) {
        this.qta = 0;
      } else if (this.qta > 500) {
        this.qta = 500;
      }
      this.debounceqta();
    },
  },
  computed: {
    carrello() {
      let prodotto = this.$store.getters.get_lista_carrello.find(
        (el) => el.art == this.prodotto.id
      );
      return prodotto ? prodotto : 0;
    },
    qta_colore: {
      get() {
        return this.$store.getters.get_qta_colore({
          id_prodotto: this.prodotto.id,
          id_colore: this.colore.id,
        });
      },
      set(qta) {
        if (!qta) {
          this.qta = 0;
        } else {
          this.qta = qta;
        }
      },
    },
    qta_totale() {
      if (this.carrello) {
        return this.carrello.colori
          .map((element) => element.numero)
          .reduce((tot, sum) => tot + sum, 0);
      } else {
        return 0;
      }
    },
    colore_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto.id +
        "/img_colore/" +
        this.colore.id +
        "?t=" +
        this.colore.md5
      );
    },
    valid() {
      return !isNaN(this.qta);
    },
  },
  methods: {
    select_col(colore) {
      this.$emit("colore_sel", colore);
    },
    set_qta() {
      if (this.colore.numero == 0) {
        if (this.qta == null || this.qta == "" || this.qta == 0) {
          return;
        }
      }
      this.loading = true;

      if (this.qta == null || this.qta == "") {
        this.colore.numero = 0;
      } else {
        this.colore.numero = this.qta;
      }

      this.$store
        .dispatch("invio_colore", {
          id_colore: this.colore.id,
          qta: this.colore.numero,
          id_prodotto: this.prodotto.id,
        })
        .then(() => {
          this.loading = false
          this.$refs.qta_box.blur();
          setTimeout(() => {
            if (this.colore.numero == 0) {
              this.removing = true;
            } else {
              this.adding = true;
            }
            setTimeout(() => {
              this.adding = false;
              this.removing = false;
            }, 2000);
          }, 100);
        });
    },
    select_box() {
      setTimeout(() => {
        this.$refs.qta_box.focus();
      }, 100);
    },
    select_text: function (e) {
      e.target.select();
    },
  },
};
