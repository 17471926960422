export default {
  data() {
    return {
      show_color: false,
    };
  },
  computed: {
    avatar_url() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.item.art;
    },
    tot_pezzi() {
      return this.item.colori
        .filter((colore) => !colore.eliminato)
        .map((col) => (col.qta_admin ? col.qta_admin : col.numero))
        .reduce((tot, num) => tot + num, 0);
    },
    prezzo_tot() {
      let tot = this.tot_pezzi * this.item.prezzo;
      return tot.toFixed(2);
    },
    nome() {
      return this.item.nome.split(" in ");
    },
  },
  methods: {
    colore_url(id) {
      if (!this.item.specie) {
        return (
          process.env.VUE_APP_API_ROOT +
          "/prodotto/" +
          this.item.art +
          "/img_colore/" +
          id
        );
      } else {
        return process.env.VUE_APP_API_ROOT + "/specie" + "/img_colore/" + id;
      }
    },
  },
};
