//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
export default {
  props: {
    serie: Object,
    specie: Object,
    itemCode: String,
  },
  data() {
    return {
      info: {
        info_serie: null,
        img_serie_info_md5: null,
        img_serie_brand_md5: null,
      },

      img_serie_brand_md5: null,
      img_serie_md5: null,
      loading_info: true,
    };
  },
  mounted() {
    this.load_info();
  },
  computed: {
    info_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/specie/" +
        this.specie.id +
        "/serie/" +
        this.serie.id +
        "/info_img?md5=" +
        this.info.img_serie_info_md5
      );
    },
    serie_img_brand_src() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/specie/" +
        this.specie.id +
        "/serie/" +
        this.serie.id +
        "/brand_img_serie?md5=" +
        this.info.img_serie_brand_md5
      );
    },
    qr_image() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/admin/lotti/qrcode?cod=" +
        this.itemCode +
        "&spec=" +
        this.specie.short_id +
        "&serie=" +
        this.serie.short_id
      );
    },
  },
  methods: {
    load_info() {
      return new Promise((resolve, reject) => {
        this.loading_info = true;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/specie/" +
          this.specie.id +
          "/serie/" +
          this.serie.id;
        axios
          .get(url)
          .then((response) => {
            this.info = response.data.info;
            this.loading_info = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.loading_info = false;
            reject();
          });
      });
    },
    formatText(input) {
      // Sostituisce **parola** con <strong>parola</strong>
      return input.replace(
        /\*\*(.*?)\*\*/g,
        '<span style="font-weight: bold; font-size: 16px;">$1</span>'
      );
    },
    async downloadQr() {
      try {
        const response = await fetch(this.qr_image);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = this.specie.nome + "_" + this.serie.nome + ".png";
        link.style.display = "none"; // Nascondi il link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading the image:", error);
      }
    },
  },
};
